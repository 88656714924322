<template>
<div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center"> 
    <div class="container">
     
      <div id="forgotpasswordpage"> 
    <p class="logo"> <a href="/" ><img src="@/static/img/flowe.png" class="imglogo"></a></p>

    <div class="pagetitle" v-if="!forgotpasswordok">
      <h2 class = "text-center">Reset password</h2>
      <p class="text-center">Enter your email and we'll send you a link to reset your password.</p>
    </div>

    <div class="form_wrapper" v-if="!forgotpasswordok">

      <form onsubmit="return false">
        <div class="form-group">
          <label>Email address</label>
          <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="name@company.com" onfocus="this.placeholder = ''" onblur="this.placeholder = 'name@company.com'" name="inputEmail" autocomplete="off" v-model="forgot.email">
          <p class="text-danger" v-if="forgot.errors" v-html = "forgot.errors"/>
        </div>
        <div class="form-group " style = "margin-top: 21px">
          <button type="submit" class="btn btn-block btn-lg btn-primary g-recaptcha"  v-if="status!='working'" @click="onRecaptcha()">Reset password</button>
          <button type="button" class="btn btn-block btn-lg btn-primary disabled" v-if="status=='working'" >Reset password <i class="fa fa-sm fa-circle-o-notch fa-spin"></i></button>
        </div>
      </form>
    </div>

    <div class="pagetitle" v-if="forgotpasswordok && status != 'resent' && status != 'resending' ">
        <h2 class = "text-center">Reset password</h2>
        <p class="text-center">We've sent an email with instructions for resetting your password.</p>
    </div>

       <div class="form-group" v-if="forgotpasswordok"> 
        <p class="text-center text-success" v-if="status == 'resent'">Email has been re-sent, check your inbox.</p>
        <p class="text-center" ><i class="fa fa-circle-o-notch fa-spin" v-if="status == 'resending'"></i></p>
        <p class="text-center" v-if="status != 'resending'">Didn't get it? <a class="text-primary" href="#resend" v-on:click.prevent="retrievepassword('resend')">Send email again</a> </p>        
      </div>
    </div>
  </div>
  </div>
</template>	
<style type="text/css" scoped>
  :root{

  /* --G700: #157815; */
  --N900: #313338;
}


html,
body {
  color: var(--N900);
  height: 100%;
}



h1, h2{ text-align: center; }

.pagetitle{
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
  margin-bottom: 1rem;
  margin-top: 3%;
}


.form_wrapper{
  margin: 0 auto;
  max-width: 350px;
}

#signuppage .form_wrapper,
#passwordreset .form_wrapper{ 
  max-width: 355px; 
}

#signuppage2 .form_wrapper{ max-width: 400px; }

#responsepage .signuppage .pagetitle{
  max-width: 500px;
}

.btn-google img{ 
  width: 24px;
  height: 24px;
  margin-right:.3em; 
}

  p.logo .imglogo{ 
    width: 200px; 
  }
  p.logo{
    text-align: center;
    width: 100%;
    margin: auto;
  }


</style>
<script type="text/javascript"></script>
<script>

  import Signinapi from '@/services/api/signin'

	export default{
		data(){
			return{
          userid : null,
          status : "idle",
          forgot: { email:"", errors:"" },
          forgotpasswordok : false,
          recaptchatoken: ""
        }
        },
        computed: {
          
        },
        filters: {},
        mounted() {


            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LcllU0gAAAAAJaHbJjZ8VizqdgjpyoTeYTv2xQs')
            document.head.appendChild(recaptchaScript)            

//

            document.title ='Flowe | Reset password'
            $('#preloader').addClass('hide')
        },
        methods: {

            onRecaptcha : function() {

              let self = this
              //e.preventDefault();
              grecaptcha.ready(function() {
                grecaptcha.execute('6LcllU0gAAAAAJaHbJjZ8VizqdgjpyoTeYTv2xQs', {action: 'submit'}).then(function(token) {
                    // Add your logic to submit to your backend server here.

                    self.recaptchatoken = token
                    self.retrievepassword('')
                });
              });

              //console.log( token )
              //document.getElementById("demo-form").submit();
              //v-on:click="retrievepassword('')"
            },

            retrievepassword : function( resend ){            
           

            if( resend == "resend" ){
              this.status = 'resending'
            }else{             
              this.status = 'working'
            }


            let self = this;

            Signinapi.Forgotpassword( this.forgot.email, this.recaptchatoken )
                .then( function(response){

                  if( response.data.ok ){     
                    self.forgotpasswordok = true

                    if( resend == "resend" ){
                      self.status = 'resent'
                    }else{
                      self.status = 'idle'
                    }

                  }else{
                     self.status = 'idle'
                    self.forgot.errors = response.data.message
                  }

                })
                .catch(error => console.log(error))
                .finally( () => {

                  

                  //do everything
                  //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
                })

          }


        },
        delimiters: ["<%","%>"]
      }
</script>